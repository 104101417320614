<template>
  <div>
    <van-nav-bar
      title="自动计时"
      left-text="返回"
      @click-left="back"
      fixed
      left-arrow
    />

    <div class="content">
      <!-- <van-field
        readonly
        name="projectId"
        clickable
        :value="item.projectName"
        label="投入项目"
        placeholder="请选择投入项目"
        @click="clickPicker(index, item)"
        :rules="[{ required: true, message: '请选择项目' }]"
      /> -->
      <div class="select">
        <van-cell title="选择项目" :value="selectValue" @click="selectXshow = true" :value-class="valueclass"/>
        <!-- <span>选择项目</span> -->

        <!-- <van-dropdown-menu>
          <van-dropdown-item
            v-model="selectValue"
            :options="selectOpt"
            @change="selectX()"
          />
        </van-dropdown-menu> -->
      </div>
      <div class="selectgx">
        <div class="selectgxtitle">选择工序<span v-show="selectgxshow" style="color:#c03131;">请选择工序</span></div>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="(item, index) in selectG" :key="index" :class="index == inbtn ? 'inbtn' : ''">
            <van-button
            class="selectgxbtn"
              type="default"
              size="small"
              @click="selectGx(item.index,index)"
              >{{ item.name }}</van-button
            >
          </van-grid-item>
        </van-grid>
      </div>
      <div class="timingBtn">
        <van-button
        class="timbutton"
        plain
         hairline
          type="info"
          size="large"
          block
          style="height: 100px"
          @click="startTiming()"
        >
          <div v-show="tBtnText" class="timint">
            {{ timingH < 10 ? "0" + timingH : timingH }}:{{
              timingM < 10 ? "0" + timingM : timingM
            }}:{{ timingS < 10 ? "0" + timingS : timingS }}
          </div>
          <span>{{ tBtnText ? "停止计时" : "开始计时" }}</span>
        </van-button>
      </div>
      <ul class="recordList">
        <li v-for="(item, index) in record" :key="index" class="">
          <div class="upper">
            <!-- 条目 -->
            <div class="entry">{{ item.data1 }}</div>
            <!-- 日期 -->
            <div class="date">{{ item.data3 }}</div>
          </div>
          <div class="below">
            <!-- 工序 -->
            <div class="procedure">{{ item.data2 }}</div>
            <!-- 计时时长 -->
            <div class="duration">{{ item.data5 }}</div>
            <!-- 计时区间 -->
            <div class="timeinterval">{{ item.data4 }}</div>
          </div>
        </li>
      </ul>
    </div>
    <van-action-sheet v-model="selectXshow">
        <van-search v-model="searchText" placeholder="请输入项目名称" @clear="sea()" @blur="sea()" @search="sea()"/>
        <div style="minHeight:300px;">
            <div v-for="(item, index) in selectOptS" :key="item.id" class="valuelist" @click="selectX(item, index)">{{item.projectName}}</div>
        </div>
    </van-action-sheet>
    <!-- <van-popup v-model="showPickerUserddp" position="bottom" style="height: 80%">
            <van-search v-model="userName" placeholder="请输入项目名称" @clear="sea()" @blur="sea()" @search="sea()"/>
            <div style="minHeight:300px;">
                <div v-for="(item, index) in projectss" :key="item.id" class="ryuan" @click="fZr(item, index)">{{item.projectName}}</div>
            </div>
        </van-popup>  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectValue: "请选择项目",
      searchText:'',
      selectXshow: false,
      selectgxshow: false,
      valueclass:null,
      inbtn:null,
      // 条目选择
      selectOpt: [],
      selectOptS:[],
      // 工序数据
      selectG: [
        { name: "工序1", index: "99" },
        { name: "工序2", index: "98" },
        { name: "工序3", index: "97" },
        { name: "工序4", index: "96" },
        { name: "工序5", index: "95" },
      ],
      // 计时器   计时数据
      timingFun: null,
      tBtnText: 0,
      // 时分秒
      timingH: 0,
      timingM: 0,
      timingS: 0,
      // 记录数据
      record: [
        {
          data1: "汽车电池一号v1234",
          data2: "组装",
          data3: "2022-03-23",
          data4: "11:40-11:50",
          data5: "0.1h",
        },
        {
          data1: "条目名称",
          data2: "工序名称",
          data3: "计时日期",
          data4: "计时区间",
          data5: "计时时长",
        },
      ],
      loadata1: null,
      startime: "",
      endtime: "",
    };
  },

  methods: {
    back() {
      history.back();
    },
    // 选择项目
    selectX(item,index) {
      console.log("selectX",item,index);
      this.selectXshow = false
      this.selectValue = item.projectName
      this.valueclass = null
    },
    sea() {
                // console.log(this.searchText.length)
                if(this.searchText.length > 0) {
                    let text = this.searchText
                    let reg = new RegExp(text)
                    let data = this.selectOptS.filter(item => reg.test(item.projectName)) //返回
                    this.selectOptS = data
                } else {
                    this.selectOptS = this.selectOpt
                }
            },
    // 选择工序
    selectGx(e,i) {
      console.log(e,i);
      this.loadata1 = e;
      this.inbtn = i
      this.selectgxshow = false
    },
    // 计时器
    startTiming() {
      if (this.selectValue == "请选择项目" || this.loadata1 == null) {
        if(this.selectValue == "请选择项目"){
            this.valueclass = "valuecolor"
        }if(this.loadata1 == null){
            this.selectgxshow = true
        }
        return
      }
        
      let date = new Date();
      let nowH = date.getHours();
      let nowM = date.getMinutes();
      if (!this.tBtnText) {
        this.tBtnText = 1;
        this.startime =
          (nowH < 10 ? "0" + nowH : nowH) +
          ":" +
          (nowM < 10 ? "0" + nowM : nowM);
        this.timingFun = setInterval(() => {
          this.timingS += 1;
          if (this.timingS == 60) {
            this.timingS = 0;
            this.timingM += 1;
            if (this.timingM == 60) {
              this.timingM = 0;
              this.timingH += 1;
            }
          }
        }, 1000);
      } else {
        this.tBtnText = 0;
        this.endtime =
          (nowH < 10 ? "0" + nowH : nowH) +
          ":" +
          (nowM < 10 ? "0" + nowM : nowM);
        clearInterval(this.timingFun);
        this.timingFun = null;
        this.timingH = 0;
        this.timingM = 0;
        this.timingS = 0;
        this.setRecord();
      }
    },
    // 添加计时记录
    setRecord() {
      let date = new Date();
      let nmon = date.getMonth() + 1;
      let today =
        date.getFullYear() +
        "-" +
        (nmon < 10 ? "0" + nmon : nmon) +
        "-" +
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
      console.log("today", today);
      let addR = {
        data1: this.selectValue,
        data2: this.loadata1,
        data3: today,
        data4: this.startime + "-" + this.endtime,
        data5: "timingbtn",
      };
      this.record.push(addR);
      console.log(this.record);
    },
    // 获取项目列表
    getseachvalue(){
        this.$axios.post("/project/getProjectList", {})
                .then(res => {
                    if(res.code == "ok") {
                        this.selectOpt = res.data
                        this.selectOptS = res.data
                        console.log("res.data",res.data);
                    } else {
                        this.$toast.fail('获取失败:'+res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
    }
  },
  mounted() {
      this.getseachvalue()
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 46px;
}
.valuecolor{
    color: #c03131;
}
.select {
    .van-cell{
        line-height: 1rem;
        padding-left: 0.45rem;
        display: block;
    }
    .van-cell__title{
        text-align: left;
        float: left;
        width: 20%;
        span{
            color: #646566;
        }
    }
  .van-cell__value{
    float: left;
      text-align: center;
      width: 80%;
  }
}
.selectgx {
  background-color: #fff;
  .selectgxtitle{
      margin-left: 0.45rem;
      text-align: left;
      font-size: 14px;
      color: #646566;
      height: 0.4rem;
      line-height: 0.4rem;
      span{
          margin-left: 0.9rem;
          font-size: 0.33rem;
      }
  }
  .inbtn{
      .selectgxbtn{
          color: #1989fa;
          border-color: #1989fa;
      }
  }
  .van-grid-item{
      height: 1.5rem;
  }
  .selectgxbtn{
      width: 85%;
    //   height: 100%;
  }
}
.timingBtn{
    padding: 2.5% 0.45rem;
    .timbutton{
        .timint{
            height: 0.8rem;
        }
    }
}
.van-dropdown-menu {
  float: right;
  width: 50%;
}
.recordList {
  padding-left: 0.45rem;
  padding-right: 0.45rem;
  background-color: #fff;
  li {
    width: 100%;
    padding-top: 4%;
    border-bottom: 1px solid #eee;
    .upper {
      height: 0.56rem;
      .entry {
        float: left;
        font-size: 0.4rem;
        color: #414141;
      }
      .date {
        float: right;
        font-size: 0.39rem;
        color: #646566;
      }
    }
    .below {
      margin-top: 0.4rem;
      height: 0.7rem;
      .procedure {
        float: left;
        color: #767676;
        font-size: 0.38rem;
      }
      .timeinterval {
        float: right;
        margin-top: 0.08rem;
        color: #969799;
        font-size: 0.35rem;
        line-height: 0.48rem;
      }
      .duration {
        margin-left: 0.3rem;
        float: right;
        margin-top: 0.08rem;
        color: #969799;
        font-size: 0.35rem;
        line-height: 0.48rem;
      }
    }
  }
}
.valuelist{
  padding: 0 0.53333rem;
line-height: 1.06667rem;
height: 1.06667rem;
color: #333;
border-bottom: 0.05333rem solid #f6f6f6;
width: 100%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
</style>